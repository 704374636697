.App {
  text-align: center;
  background-color: #ffffff;
  /* width: 100vw;
  height: 100vh; */
  /* display: flex; */
  color: white;
}

body {
  padding: 0%;
  margin: 0;
}

nav {
  height: 60px;
  width: 100%;
  margin: 0;
  border-bottom: 1px solid grey;
  display: grid;
  place-items: center;
}

nav h1 {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  color: white;
  font-size: 45px;
}
.game {
  width: 100vw;
  height: calc(100vh - 170px);
  display: flex;
  align-items: center;
  padding-top: 30px;
  flex-direction: column;
}

.board {
  width: 450px;
  height: 550px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}

.row {
  flex: 33%;
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.letter {
  flex: 33%;
  height: 100%;
  border: 1px solid grey;
  margin: 5px;
  display: grid;
  place-items: center;
  font-size: 30px;
  font-weight: bolder;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

#correct {
  background-color: #B71000;
}

#almost {
  background-color: #EFC3C0;
}

#error {
  background-color: #869495;
}

/*
.keyboard {
  width: 700px;
  height: 300px; 
  margin-top: 2rem;
  margin-bottom: 0rem; 
}


.line1 {
  flex: 33%;
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: center;
}
.line2 {
  flex: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.line3 {
  flex: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.key {
  width: 2.75rem;
  height: 3.5rem;
  margin: 0.2rem;
  border-radius: 0.25rem;
  display: grid;
  place-items: center;
  font-size: 1rem;
  font-weight: bold;
  background-color: #E0F2F6;
  color: #000000;
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
}

#big {
  width: 4.5rem;
}
#disabled {
  background-color: #869495;
}
*/

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
